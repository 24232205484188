.profile-page {
  background: var(--dark);
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;

  &__content {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 24px;

    &__close {
      font-size: 30px;
      font-weight: 400;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
  
    h1 {
      margin-bottom: 40px;
      text-align: center;
      color: var(--primary);
    }

    &__avatar {
      width: 120px;
      margin: 0 auto;
    }
  
    h2 {
      color: var(--primary);
      margin-top: 40px;
    }

    &__edit {
      display: flex;
      justify-content: center;
      margin-top: 12px;
      color: var(--light50);
      cursor: pointer;
    }
  
    &__statistics {
      margin-top: 40px;
  
      p {
        font-size: 14px;
      }
  
      table {
        width: 100%;
  
        th {
          text-align: start;
        }
      }
    }
  
    &__install-btn {
      margin-top: 40px;
  
      &__icon {
        width: 24px;
        height: 24px;
        transform: translateY(5px);
      }
  
      .btn {
        width: 100%;
      }
  
      > P {
        text-align: start;
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
      }
    }
  
    &__modal {
      position: absolute;
      left: 0;
      z-index: 100;
      background: var(--dark);
      padding: 24px;
      width: 90%;
      margin-left: 50%;
      transform: translateX(-50%);
      border-radius: 15px;
  
      &__btns {
        display: flex;
        column-gap: 24px;
        justify-content: end;
        margin-top: 24px;
  
        >  * {
          min-width: fit-content;
        }
      }
    }
  
    &__delete-btn {
      color: var(--light50);
      width: 100%;
    }
  
    &__logout-btn {
      width: 100%;
    }
  }
}