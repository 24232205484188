.number-input-modal {
  position: fixed;
  left: 0;
  top: 20%;
  width: 80%;
  max-width: 600px;
  background: var(--dark);
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  z-index: 100;
  padding: 24px;

  &__content {
    max-height: 45vh;
    overflow-y: scroll;
    margin: 12px 0;
    padding: 12px 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &__hide {
      text-align: left;
      margin-top: -12px;
      margin-bottom: 12px;
  
      > input {
        margin-right: 4px;
      }
  
      label {
        font-weight: 400;
        font-size: 12px;
      }
    }
  
    img {
      width: 100%;
      border: 1px solid var(--dark50);      
    }
  
    &__with-text {
      font-weight: 400;
      margin-top: 4px;
      margin-bottom: 12px;
      text-align: start;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
  
      span {
        color: var(--primary);
      }
    }

  }

  
  &__btns {
    display: flex;
    justify-content: flex-end;
    column-gap: 12px;

    > * {
      margin: 0;
      min-width: fit-content;
      font-size: 18px;
    }

    :last-child {
      color: var(--primary);
    }
  }
  
}