:root {
  // Colors
  --main: #fed173;

  --primary: #AAE5F8;
  --primary75: #80D9F5;
  --primary50: #AAE5F8;

  --secondary: #C0D684;
  --secondary75: #D0E0A3;
  --secondary50: #DFEAC1;

  --yellow: #FFE34F;
  --yellow75: #FFEC8A;
  --yellow50: #FBEDC4;

  --success: #2c9d5a;
  --success75: #2c9d59ab;

  --red: #EB5757;
  --red75: #EA9A9A;

  --darkText: #161616;
  --lightText: #F3F3F3;
  --lightText50: #f3f3f385;

  --dark: #161616;
  --dark90: #161616e7;
  --dark75: #505050;
  --dark50: #8A8A8A;

  --light: #F3F3F3;
  --light90: #f3f3f3e7;
  --light50: #f9f9f980;
  --light25: #f3f3f342;
  --white: #FFF;

  // Fonts
  --mainFont: 'Montserrat', sans-serif;
  --smallFont: 'Roboto', sans-serif;


  // Screen sizes
  --mobile: '500px';
  --smallScreen: '1100px';

  // Misc
  --shadow: 3px 3px 0px var(--dark);
}