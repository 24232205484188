.create-battle-page__modal {
  position: absolute;
  left: 0;
  top: 20%;
  z-index: 100;
  background: var(--dark);
  padding: 24px;
  width: 90%;
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;

  p {
    margin-bottom: 12px;
  }

  &__btns {
    display: flex;
    column-gap: 24px;
    justify-content: end;
    margin-top: 24px;

    >  * {
      min-width: fit-content;
    }
  }
}