.cookie-banner {
  position: absolute;
  top: 24px;
  left: 0;
  width: 90%;
  max-width: 600px;
  margin-left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 12px;
  color: var(--darkText);
  border-radius: 5px;
  z-index: 100;

  > p {
    font-weight: 400;
    margin-bottom: 12px;
  }

  h4 {
    margin-top: 40px;
  }

  input {
    margin-bottom: 40px;
    margin-right: 8px;
  }

  > .btn {
    width: 100%;
    margin: 12px 0;
  }

  table {
    background: var(--light);
    text-align: left;
  }

  td {
    padding-bottom: 24px;
    font-weight: 400;
  }

  &__links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 400;
    margin-top: 24px;
    cursor: pointer;
    user-select: none;

    > p {
      font-size: 10px;
      text-align: center;
      user-select: none;
    }
  }
}

.cookie-banner-mask {
  position: fixed;
  background-color: var(--dark);
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 11;
}