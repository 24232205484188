.add-photo-modal {
  position: absolute;
  left: 0;
  top: 20%;
  width: 80%;
  max-width: 600px;
  background: var(--dark);
  margin-left: 50%;
  margin-bottom: 40px;
  transform: translateX(-50%);
  border-radius: 15px;
  z-index: 100;
  padding: 24px;
  text-align: left;
  overflow: auto;

  &__content {
    max-height: 45vh;
    overflow-y: scroll;
    margin: 12px 0;
    padding: 12px 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
    > p {
      font-weight: 400;
      margin-bottom: 24px;
    }
  
    input {
      display: none;
    }
  
    img {
      width: 100%;
      border: 1px solid var(--dark50);
    }
  
    &__upload-text {
      margin-top: 4px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: -0 !important;
  
      span {
        color: var(--main) !important;
      }
    }
  
    &__battle-list {
      margin-top: -18px;
      border-bottom: 1px solid var(--light);

      > * {
        cursor: pointer;
      }
  
      &__target-list {
        padding-left: 12px;
  
        > * {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
        }
  
        h4 {
          margin: 0;
        }
      }
    }

  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    column-gap: 12px;
    margin-top: 12px;

    > * {
      margin: 0;
      min-width: fit-content;
      font-size: 18px;
    }

    :last-child {
      color: var(--primary);
    }
  }

  .scroll-to {
    margin-top: 40px;
  }
}