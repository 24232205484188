.speech-card {
  position: relative;
  margin: 24px auto -24px auto;
  width: 100%;
  max-width: 600px;
  background: var(--light);
  border: 4px solid var(--dark);
  font-weight: 400;
  border-radius: 15px;
  box-shadow: var(--shadow);
  padding: 12px;
  color: var(--dark);

  &__top-line {
    display: flex;
    align-items: baseline;
    margin-top: -28px;
    margin-bottom: 12px;
    column-gap: 12px;
  }

  &__content {
    p,
    ul {
      margin-bottom: 12px;
    }

    li {
      margin-left: 24px;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__btn-cont {
    display: flex;
    justify-content: flex-end;
  }
}