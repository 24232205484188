.home-page {
  // margin-top: 50%;

  &__version {
    position: absolute;
    top: 0;
    right: 0;
    text-align: end;
    font-size: 11px;
    color: var(--dark);
    padding: 2px;
  }

  &__screen-width-alert {
    background: var(--red75);
    border: 1px solid var(--red);
    border-radius: 15px;
    padding: 24px;
    position: relative;
    margin-top: 24px;
    font-weight: 400;
    max-width: 600px;
    margin: 24px auto;

    p {
      margin-bottom: 12px;
    }
  }
}