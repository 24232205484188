.view-photo-modal {
  position: absolute;
  left: 0;
  top: 20%;
  width: 80%;
  max-width: 600px;
  background: var(--dark);
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  z-index: 100;
  padding: 24px;
  padding-bottom: 12px;

  &__content {
    max-height: 45vh;
    overflow-y: scroll;
    margin: 12px 0;
    padding: 12px 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    input {
      display: none;
    }
  
    img {
      width: 100%;
      border: 1px solid var(--dark50);
      min-height: 200px;
    }
  }


  &__btns {
    display: flex;
    justify-content: flex-end;
    column-gap: 12px;

    > * {
      margin: 0;
      min-width: fit-content;
      font-size: 18px;
    }

    :last-child {
      color: var(--primary);
    }
  }
}