.auth-page {
  text-align: center;
  height: 100vh;
  width: 100%;
  background: var(--dark);
  position: absolute;
  left: 0;
  top: 0;
  padding: 24px;

  &__credentials-container {
    text-align: start;
    max-width: 700px;
    margin: 40px auto;
    max-width: 600px;

    h3 {
      color: var(--primary);
    }

    .btn {
      width: 100%;
    }

    &__log-in-info {

      h2 {
        color: var(--primary);
      }

      &:first-child {
        margin-bottom: 60px;
      }
    }

    &__no-account {
      font-weight: 400;
      margin-top: 16px;

      span {
        color: var(--primary);
        cursor: pointer;
      }
    }
  }

  &__guide {
    text-align: start;
    font-weight: 400;
    margin-top: 24px;

    span {
      color: var(--primary);
      cursor: pointer;
    }
  }

  &__cookie-banner {
    max-width: 600px;
    margin: 0 auto;
  }
}