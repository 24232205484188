.battle-page {

  &__corner-1 {
    position: absolute;
    left: 0;
    top: 12px;
  }

  &__corner-3 {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__corner-4 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__back-arrow {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  &__vs-text {
    display:grid;
    grid-template-columns: 1fr 16px 1fr;
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 20px;

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .vs {
      overflow: visible;
      text-align: center;
      font-size: 14px;
    }
  }

  &__prize {
    margin-top: 24px;

    > * {
      margin-bottom: 4px;
    }
  }

  &__target {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &__textarea {
    min-height: 60px;
    overflow-y: auto;
    word-wrap: break-word
  }
}