.card-container {
  position: relative;
  margin: 24px auto;
  max-width: 600px;

  .card-title,
  .card-back-btn {
    height: 40px;
    border-radius: 15px;
    border: 4px solid var(--dark);
    background: var(--light);
    display: inline-block;
    color: var(--dark);
    line-height: 40px;
    font-size: 18px;
    box-sizing: content-box;
    padding: 0 8px;
    font-family: "Press Start 2P", monospace;
    box-shadow: var(--shadow);
  }

  .card-back-btn {
    cursor: pointer;
  }
}

.card {
  position: relative;
  padding: 12px;
  box-shadow: var(--shadow);
  font-weight: 400;
  border-radius: 15px;
  border: 4px solid var(--light);
  color: var(--light);
  max-width: 600px;
  z-index: 1;
  background: var(--dark);
  margin: 6px 0 6px 0;

  &__back-post {
    position: absolute;
    top: -41px;
    left: 7px;
    cursor: pointer;
  }

  &--light {
    background: var(--light);
    color: var(--dark);
    border: 4px solid var(--dark);
  }

  // &--menu {
    // position: relative;
    // margin: 150px auto 24px auto;
    // position: relative;
    // background: var(--dark);
    // border: 4px solid var(--light);
    // z-index: 1;
    // padding: 12px;
    // max-width: 600px;
    // border-radius: 15px;
    // box-shadow: var(--shadow);
    // color: var(--light) !important;
    // opacity: 1;
  // }

  
  // @keyframes expand {
  //   0% {
  //     height: 1px;
  //   }
  //   100% {
  //     height: 175px;
  //   }
  // }

  .corner-1 {
    position: absolute;
    top: -8px;
    left: -8px;
  }

  .corner-2 {
    position: absolute;
    top: -8px;
    right: -8px;
    transform: rotate(90deg);
  }

  .corner-3 {
    position: absolute;
    bottom: -8px;
    right: -8px;
    transform: rotate(180deg);
  }

  .corner-4 {
    position: absolute;
    bottom: -8px;
    left: -8px;
    transform: rotate(270deg);
  }

  &__back-arrow {
    position: absolute;
    top: 16px;
    left: 16px;

    &:active {
      transform: translateY(2px);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}