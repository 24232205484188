.input-container {
  width: 100%;
}

.input,
.input-default {
  font-size: 16px;
  height: 32px;
  width: 100%;
  background-color: transparent;
  color: var(--dark);
  font-weight: bold;
  outline: none;
  border-bottom: 1px solid var(--dark50);

  &:focus {
    border-bottom: 2px solid var(--main);
  }
}

.input::placeholder,
.input-default::placeholder {
  color: var(--dark);
  font-size: 14px;
  opacity: .2;
}

.input--disabled {
  background: var(--dark50);
}

.input--dark {
  background: var(--dark) !important;
  color: var(--light) !important;
  border-bottom: 1px solid var(--light50);
  background: var(--dark);
  -webkit-text-fill-color: var(--light);

  &::placeholder {
    color: var(--light);
  }
}

.input-container input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}