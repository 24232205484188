.battle-entry {
  position: relative;
  padding: 4px;
  margin: 12px;
  margin-left: 24px;
  cursor: pointer;
  border-bottom: 1px solid #8a8a8a2b;

  p {
    color: var(--light50);
  }

  &:hover {
    background: var(--light);
    color: var(--dark);

    p {
      color: var(--dark);
    }

    &::before {
      content: '';
      position: absolute;
      width: 0; 
      height: 0; 
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      
      border-left: 16px solid var(--light);
      left: 0;
      top: 50%;
      transform: translate(-125%, -50%);
    }
  }

  > * {
    margin: 4px 0;
  }
  
  img {
    position: absolute;
    top: 0;
    right: -12px;
  }
}