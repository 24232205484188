.spinner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--light {
    > * {
      background-color: var(--light) !important;
    }
  }

}

.box-one {
  animation: goLeft 1.5s infinite;
}

.box-two {
  animation: goLeft 1.5s reverse infinite;
}

.box-three {
  animation: goLeft 1.5s infinite;
}

.box {
  background-color: var(--dark);
  width: 8px;
  height: 8px;
}

@keyframes goLeft {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(-24px);
  }
  100% {
    transform: translate(0);
  }
}