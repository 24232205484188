.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: var(--dark);
  z-index: 1;

  &__content {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  
    &__camera {
      cursor: pointer;
      padding: 8px;
      border: 2px solid var(--dark);
      border-radius: 50%;
      box-shadow: var(--shadow);
      background: var(--main);
      height: 56px;
      width: 57px;

      &:hover {
        transform: translateY(1px);
      }

      &:active {
        transform: translateY(2px);
      }

      svg {
        transform: translateY(2px);
      }
    }

    &__swords {
      position: relative;

      &__notification-dot {
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        right: -4px;
        background: var(--red);
        border-radius: 50%;
      }
    }

  }
}