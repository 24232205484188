.target-line-container {

  &__target-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    font-size: 18px;
    border-left: 1px solid var(--dark50);
    border-right: 1px solid var(--dark50);
    padding-left: 4px;
    padding-right: 8px;

    &__start {
      display: flex;
      flex-direction: column;

      &__title {
        font-size: 20px;
      }

      &__icon-row {
        display: flex;
        opacity: 0.8;
        margin-top: -2px;

        > * {
          display: flex;
          align-items: center;
          margin-right: 12px;

          :first-child {
            margin-left: -5px;

            g {
              transform: scale(0.6);
              transform-origin: center;
              opacity: .6;
            }
          }

          p {
            font-size: 10px;
            margin-left: -4px;
          }
        }
      }
    }
  
    &__icons {
      display: flex;
      column-gap: 4px;
      align-items: center;
      opacity: 0.8;
    }
  }

  &__description-cont {
    margin-top: -24px;
    margin-bottom: 24px;
    padding-top: 12px;
    border-left: 1px solid var(--dark50);
    border-right: 1px solid var(--dark50);

    &__top-line {
      display: flex;
      align-items: center;

      :first-child {
        path {
          transform: scale(0.7);
          transform-origin: center;
        }
      }
    }

    textarea {
      border: none !important;
    }
  }

  &__description {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 24px;
    background: var(--dark);
    color: var(--light);
    border: 1px solid var(--light50);
  }
}
