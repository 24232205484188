.ancillary {
  position: absolute;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  padding: 24px;
  padding-bottom: 100px;
  background: white;
  color: var(--darkText);
  font-weight: 400;
  z-index: 101;
  text-align: start;
  overflow-x: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    line-height: 24px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }

  h1 {
    margin-bottom: 40px;
  }

  h3 {
    margin-top: 24px;
  }

  p {
    margin-bottom: 12px;
  }

  ul {
    margin-left: 24px;
  }

  i {
    color: #9c9c9c;
  }

  .btn {
    margin-top: 24px;
    width: 100%;
  }
}