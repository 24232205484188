.photo-input-modal {
  position: fixed;
  left: 0;
  top: 20%;
  width: 80%;
  max-width: 600px;
  background: var(--dark);
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  z-index: 100;
  padding: 24px;
  text-align: left;

  &__header {
    border-bottom: 1px solid var(--light);
    padding-bottom: 12px;
  }

  &__content {
    max-height: 45vh;
    overflow-y: scroll;
    margin: 12px 0;
    padding: 12px 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    > p {
      font-weight: 400;
    }
  
    input {
      display: none;
    }
  
    img {
      width: 100%;
      border: 1px solid var(--dark50);
    }
  
    &__upload-text {
      margin-top: 4px;
      text-align: center;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
  
      span {
        color: var(--primary);
      }
    }
  }


  &__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 12px;
    border-top: 1px solid var(--light);

    > * {
      margin: 0;
      min-width: fit-content;
      font-size: 18px;
    }

    :last-child {
      color: var(--primary);
    }
  }
}