.health-bar {
  height: 12px;
  border-radius: 5px;
  background-color: var(--red);
  border: 2px solid var(--dark);

  &--shake {
    animation: shake 0.5s linear;
  }

  &--pulse {
    animation: pulse 1s infinite;
  }

  &__plusMinus {
    position: absolute;
    top: -26px;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: var(--light);

    &--plus {
      right: -25px;
      color: var(--success);
    }
  
    &--minus {
      left: -25px;
      color: var(--red);
    }
  }


  &__event {
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: var(--light);
    animation: rise 1s linear forwards;

    &--minus-1 {
      color: var(--red75);
    }
  
    &--plus-1 {
      color: var(--success);
    }
  }

  &__warning {
    position: absolute;
    right: -7px;
    top: -30px;
    color: var(--red);
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: var(--light);
  }

}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rise {
  0% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(-40px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    border-left: 4px solid var(--red);
  }
  50% {
    transform: scale(1.05);
    border-left: 4px solid var(--red);
  }
  100% {
    transform: scale(1);
    border-left: 4px solid var(--red);
  }
}