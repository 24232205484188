.customize-modal {
  background: var(--dark);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;

  &__content {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
  }

  h1 {
    margin-bottom: 40px;
    text-align: center;
    color: var(--primary);
  }

  .avatar-carousel {
    display: flex;
    justify-content: center;
    width: 100%;
    column-gap: 24px;
  
    &__card {
      position: relative;
  
      &--subLeft {
        transform: translateX(14%) scale(0.8);
        cursor: pointer;
      }
  
      &--subRight {
        transform: translateX(-14%) scale(0.8);
        cursor: pointer;
      }
    }
  
    &__nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;

      h2 {
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &__colors {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }

  &__btn-cont {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    column-gap: 12px;

    > * {
      // flex-grow: 1;
      width: 100%;
    }
  }
}
