.contestant-card {
  position: relative;
  height: 315px;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);

  > * {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__card {
    position: relative;
    width: 200px;
  }

  &__username {
    top: 24px;
    font-size: 14px;
  }

  &__avatar {
    width: 120px;
    top: 68px;
  }

  &__health-bar-cont {
    bottom: 44px;
    width: 140px;
  }

  &__level {
    bottom: 10px;
    font-family: "Press Start 2P", monospace;
    font-size: 14px;
  }
}