.day-view {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 100px 1fr 75px 1fr 100px;

  &__spinner {
    height: 50px;
    width: 50px;
    margin-left: 51vw;
    margin-top: 50vh;
    z-index: 100;
    transform: scale(1.5);
  }

  &__back-card {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    border: 4px solid var(--dark);
    border-radius: 5px;
    padding: 4px 6px;
    cursor: pointer;
    color: var(--dark);
  }

  &__player-section {
    height: 40%;
    position: relative;
    
    &--opponent {
      grid-row: 2;
    }
  }

  &__vs {
    position: relative;
    text-align: center;
    grid-row: 3;
  }

  &__targets-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4px 1fr;

    .line {
      border-left: 4px dashed var(--light);
      opacity: .1;
      width: 4px;
    }

    &__column {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        .checkmark {
          background: var(--light50) !important;
          border: none !important;
        }
      }
    }
  }
}

.battle-action-menu {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;

  &:hover .battle-action-menu__content {
    transform: scale(1);
    transform-origin: top left;
    padding: 40px 24px;
    transition: all 0.3s linear;
    overflow: hidden;
    border: 4px solid var(--dark);
  }

  &__title {
    position: absolute;
    top: -18px;
    left: 19px;
    background: #929CA1;
    padding: 6px 8px;
    border: 4px solid var(--dark);
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Press Start 2P';
    font-weight: 400;
    box-shadow: 0px 0px 2px 2px inset white;
    color: var(--dark);
  }
  
  &__content {
    transform: scale(0);
    transform-origin: top left;
    background: #929CA1;
    border-radius: 15px;
    box-shadow: 0px 0px 2px 2px inset white;
    color: var(--dark);
    transition: max-height 0.3s linear;
    overflow: hidden;
  }

}