.select,
.select-default {
  font-size: 16px;
  font-weight: bold;
  height: 34px;
  width: 100%;
  color: var(--dark);
  background: url('../icon/pngs/down-chevron-dark.png') no-repeat;
  background-position: top 5px right 14px;
  appearance: none;
  cursor: pointer;
  border-bottom: 1px solid var(--dark50);

  &::placeholder {
    color: var(--dark50);
    font-size: 14px;
  }

  &--disabled {
    background: url('../icon/pngs/down-chevron.png') no-repeat var(--light75);
    background-position: top 5px right 14px;
    cursor: default;
  }

  &--dark {
    color: var(--light);
    border-bottom: 1px solid var(--light50);
    background: url('../icon/pngs/down-chevron.png') no-repeat;
    background-position: top 5px right 14px;
  }

  &__dropdown {
    position: absolute;
    max-height: 200px;
    width: 100%;
    border-radius: 5px;
    margin-top: 4px;
    overflow: auto;
    border: 1px solid var(--dark);
    background: var(--light);
    z-index: 100;

    &--hidden {
      position: absolute;
      max-height: 0px;
      overflow: hidden;
    }

    &--dark {
      border: 1px solid var(--light);
      background: var(--dark);
    }
  }
}