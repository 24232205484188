
* {
  margin: 0; 
  padding: 0; 
  border: 0;
  box-sizing: border-box;
  font-family: monospace;
  font-size: 16px;
}

.full-screen-mask {
  position: fixed;
  background-color: #f9f9f930;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 11;
  backdrop-filter: blur(2px);
}

body {
  position: relative;
  background: var(--dark);
  font-weight: bold;
  color: var(--lightText);
  padding-bottom: 120px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 12px;
    font-weight: bold;
    user-select: none;
    font-family: "Press Start 2P", monospace;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }

  p {
    user-select: none;
  }

  a {
    text-decoration: none;
    color: var(--lightText);
  }
}

textarea {
  background: var(--light);
  border: 1px solid var(--dark);
  width: 100%;
  padding: 4px;
  min-height: 100px;

  &:focus-visible {
    border: 2px solid var(--main);
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border-bottom: 1px solid var(--dark);
  // -webkit-text-fill-color: var(--dark);
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-appearance: none;
  font-family: monospace;
}

@-webkit-keyframes autofill {
  0%,100% {
      color: #666;
      background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.warning {
  color: var(--red);
}

input[type=checkbox] {
  accent-color: var(--primary);
}