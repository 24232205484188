.form-element {
  margin-bottom: 24px;
  position: relative;
  text-align: start;
  font-weight: 400;

  &--error input {
    border-color: var(--red);
  }
  
  &--error select {
    border-color: var(--red);
  }

  &__label {
    margin-bottom: 4px;
    color: var(--darkText50);
  }
  
  &__label > * {
    color: inherit;
  }
  
  &__error-message {
    margin-top: 2px;
    color: var(--red);
    font-weight: 400;
  }
}