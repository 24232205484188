.select-option {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--dark);

  &:hover {
    background: var(--main);
  }

  &--disabled {
    opacity: 0.3;
    cursor: default;
  }

  &--dark {
    border-bottom: 1px solid var(--light50);
  }
}