.checkbox-container {
  width: fit-content;
  display: flex;
  margin-bottom: 12px;

  &__text {
    text-align: start;
  }
}

.checkbox {
  display: block;
  position: relative;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--light);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .checkmark {
    border: var(--success) solid 2px;
  }
  
  input:checked ~ .checkmark {
    background-color: var(--light);
    border: var(--success) solid 1px;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkmark:after {
    left: 4px;
    top: 0;
    width: 5px;
    height: 12px;
    border: solid var(--success);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &--otherUser {
    &:hover input ~ .checkmark--otherUser {
      border: var(--success) solid 2px;
    }

    input:checked ~ .checkmark--otherUser {
      border: var(--success) solid 1px;
    }

    .checkmark--otherUser:after {
      border: solid var(--success);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .checkmark:after {
      height: 10px;
    }
  }

}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: var(--dark) solid 1px;
  border-radius: 2px;
  cursor: pointer;
  background: var(--light);

  &__icon {
    width: 8px;
    height: 8px;
    position: absolute;
    bottom: 0;
    right: 1px;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.checkbox-number-container {
  position: absolute;

  .checkbox-container__text {
    margin-left: 6px !important;
  }
}

.checkbox-number-input {
  height: 18px;
  border: var(--dark) solid 1px;
  border-radius: 2px;
  cursor: pointer;
  background: var(--light);
  padding: 0 4px;
  text-align: center;
  font-weight: bold;
  color: var(--dark);
  font-size: 16px;
  color: var(--success);

  &--active {
    width: fit-content;
    min-width: 18px;
    line-height: 18px;

    &--otherUser {
      color: var(--success);
    }
  }

  &--inactive {
    width: fit-content;
    min-width: 18px;
    line-height: 18px;

    &--otherUser {
      background-color: var(--light50);
    }
  }
}