.battle-response-page {
  text-align: start;
  padding-top: 24px;

  &__info {
    margin: 12px 0;

    span {
      color: var(--dark50);
    }
  }

  h3 {
    color: var(--primary);
    margin-top: 24px;
    text-align: center;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
    column-gap: 8px;

  }

  textarea {
    border: none;
    background: var(--light);
    color: var(--dark);
    border-left: 1px solid var(--dark50);
    border-right: 1px solid var(--dark50);
  }
}