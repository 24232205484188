.color-picker {
  display: flex;
  flex-direction: column;

  label {
    font-size: 16px;
    cursor: pointer;
  }

  &__click {
    padding-left: 6px;
    transform: translateY(-31px);
    font-size: 12px;
    pointer-events: none;
  }

  #profile-color-picker {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transform: translateX(-5px);
  }
  #profile-color-picker::-webkit-color-swatch {
    border-radius: 5px;
    border: none;
  }
  #profile-color-picker::-moz-color-swatch {
    border-radius: 5px;
    border: none;
  }
}