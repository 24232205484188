.temp-target {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 18px;
  }

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      stroke: var(--light);
      fill: none;
      cursor: pointer;
      margin: 0 2px;
    }
    
  }
}