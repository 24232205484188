// THIS ALSO COVERS CSS FOR BattleCalender.tsx BLOCK TARGETS PIECE
.dashboard {
  padding-top: 24px;

  &__notifications {
    margin-bottom: 24px;
    
    span {
      color: var(--dark);
      font-weight: 800;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    h4 {
      margin: 0;
      padding-left: 8px;
    }
  }

  &__battle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &__text {
      font-weight: 400;
      padding-left: 4px;
      
      > * {
        font-size: 14px;
      }
      
      &__title {
        font-weight: 800;
        font-size: 18px;
      }
    }

    &__icon-line {
      display: flex;
      align-items: center;
      opacity: 0.7;
      white-space: nowrap;
      flex-wrap: wrap;

      p {
        margin: 0;
        font-size: 14px;
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    &__row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .checkbox-container {
        transform: scale(1.3) translateY(-4px);
      }
    }

    &__extend {
      margin-left: 32px;
      margin-bottom: 40px;
      font-weight: 400;

      &__submit {
        background: var(--main);
        color: var(--dark);
        padding: 4px 8px;
        border-radius: 5px;
        margin-top: 4px;
        margin-bottom: 12px;
        cursor: pointer;
      }
    }

  }
}