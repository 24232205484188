.add-contestant {

  form {
    margin-top: 24px;
    margin-bottom: 24px;

    .btn {
      float: right;
      min-width: 144px;
      margin-top: -8px;
    }
  }

  &__opponent-list {
    margin-bottom: 24px;

    > * {
      text-align: start;
      padding: 4px 0;
      cursor: pointer;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  &__btn-container {
    display: flex;
    justify-content: space-between;
    
    > * {
      min-width: 144px;
    }
  }
}