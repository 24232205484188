.app {
  padding: 24px;

  &__mask {
    position: fixed;
    top: 0;
    height: 102vh;
    filter: blur(5px);
    z-index: 1;
  }

  &__background {
    background-image: url(../public/images/newCabin.png);
    background-color: white;
    background-repeat: repeat-x;
    background-size: contain;
    position: fixed;
    background-position: bottom;
    height: 100%;
    width: 100vw;
    margin: 0 auto;
    left: 0;
    top: 0;
    opacity: 1;

    &--cabin {
      background-image: url(../public/images/backgroundCabin.png) !important;
    }

    &--table {
      background-image: url(../public/images/newTable.png) !important;
      @media (max-width: 900px) {
        background-size: cover;
      }
    }

    @media (max-width: 700px) {
      background-size: cover;
    }
  }

  &__cloud {
    position: absolute;
    top: 210px;
    right: 0px;
    animation: floatLeft 120s;
  }

  @keyframes floatLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-600px);
    }
  }

  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--dark);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.header {
  width: 100%;
  max-width: 600px;
  position: relative;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.toast-container {
  position: fixed;
  top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  z-index: 100;
}

.text-area-count {
  font-family: var(--smallFont);
  font-weight: 400;
  text-align: end;
}

.menu {
  margin-bottom: 40px;

  &__item {
    border-bottom: 1px solid var(--light50);
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-bottom: 12px;
    font-family: var(--smallFont);
    font-weight: 400;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 100px;
  padding: 24px;
  z-index: 1;
  overflow: hidden;
}

.icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.icon--large {
  cursor: pointer;
  width: 32px;
  height: 32px
}