.notifications-modal {
  background: var(--dark);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;

  &__content {
    max-width: 600px;
    margin: 0 auto;

    &__warning {
      margin-top: 40px;
      font-size: 14px;
      color: var(--yellow75);
    }
  }

  &__btn {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .btn {
      width: 100%;
    }
  }

  h1 {
    margin-bottom: 40px;
    text-align: center;
    color: var(--primary);
  }
}