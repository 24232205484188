.calendar-view {
  position: relative;
    max-width: 600px;
    margin: 0 auto;

  &__battle-details-cont {
    position: relative;
    margin-top: 40px;
  }
}

.battle-details {

  &__block-target-line {
    padding-left: 8px;
    padding-right: 4px;
    border-left: 1px solid var(--dark50);
    border-right: 1px solid var(--dark50);
    margin-bottom: 12px;

    &--number {
      padding-left: 5px;
    }
  }

  &__icon--pulse {
    animation: pulse 1.5s infinite;
    cursor: pointer;
  }

  &__back-card {
    background: #ede5d2;
    padding: 6px 8px;
    font-size: 16px;
    font-family: "Press Start 2P";
    font-weight: 400;
    color: var(--dark);
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 2px 2px 2px 2px var(--dark);
    position: absolute;
    bottom: -39px;
    left: 0;
    border-radius: 5px;
    cursor: pointer;
  }

  &__head-to-head {
    display: flex;
    justify-content: space-around;
    height: 100px;
    margin-bottom: 70px;

    > * {
      width: 50%;

      h4 {
        width: 100%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .health-bar {
        max-width: 130px;
        margin: 0 auto;
      }
    }

    svg {
      height: 100%;
    }
  }

  &__entry-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__entry {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      margin-right: 8px;
    }

    &__title {
      opacity: .5;
      font-size: 12px;
    }
  }

  &__targets-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 8px;

    p {
      opacity: .5;
    }
  }

  &__description-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    p {
      opacity: .5;
    }
  }

  textarea {
    background: inherit;
    border: none;
    border-left: 1px solid var(--dark50);
    border-right: 1px solid var(--dark50);
    color: var(--dark);
  }
}

.battle-calendar-cont {
  position: relative;
  margin-top: 70px;
}

.battle-view-card-title {
  background: #ede5d2;
  padding: 6px 8px;
  padding-left: 36px;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Press Start 2P";
  font-weight: 400;
  box-shadow: 2px 0px 2px 2px var(--dark);
  color: var(--dark);
  position: absolute;
  top: -15px;
  left: -2px;
  transform: rotate(-3deg);

  &__nail {
    position: absolute;
    top: -13px;
    left: -9px;
  }
}

.battle-view-card {
  position: relative;
  width: 100%;
  background: #ede5d2;
  box-shadow: 2px 2px 2px 2px var(--dark);
  color: var(--dark);
  padding: 24px 12px;
  margin-top: 24px;
  
  &__LB {
    position: absolute;
    bottom: 0;
    left:0;
  }
  
  &__RB {
    position: absolute;
    bottom: 0;
    right:0;
  }
  
  &__RT {
    position: absolute;
    top: -8px;
    right: -2px;
  }
}

.battle-calendar {
  display: grid;
  grid-gap: 2px;

  > * {
    text-align: center;
  }
}
 
  
.battle-calendar-content {
  transform: scale(0);
  transform-origin: top left;
  background: #929CA1;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 2px inset white;
  color: var(--dark);
  transition: max-height 0.3s linear;
  overflow: hidden;
}

.battle-block-targets-cont {
  position: relative;
  margin-top: 40px;

  &__content {

    &__grid {

      h4 {
        margin-top: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 4px;
      }
    }
  }
}
