.btn,
.btn-default {
  font-weight: bold;
  width: auto;
  background-color: white;
  border: 2px solid var(--dark);
  box-shadow: var(--shadow);
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 44px;
  font-size: 24px;
  background: var(--main);
  color: var(--dark);
  margin: 12px 0;
  padding: 0 8px;
}

.btn > svg {
  margin-right: 4px;
}

.btn:hover,
.btn-default:hover {
  transform: translateY(1px);
}

.btn:active,
.btn-default:active {
  transform: translateY(2px);
}

.btn-brick {
  background: url('./stoneTileLight.png'); 
  color: var(--light);
  border: 2px solid var(--light);
}

.btn-primary {
  background: var(--light);
  border: 2px solid var(--primary);
  color: var(--primary);
}

.btn-white {
  background: var(--light);
  border: 2px solid var(--dark);
  color: var(--dark);
}

.btn-secondary {
  background: var(--light);
  border: 2px solid var(--secondary);
  color: var(--secondary);
}

.btn-cancel {
  color: var(--light);
  background: var(--red);
}

.btn-text {
  margin: 0;
  border: none;
  background-color: transparent;
  color: var(--lightText);
  min-width: fit-content;
  box-shadow: none;
}

.btn-disabled {
  border: none;
  background-color: grey;
  color: var(--light50);
  cursor: default;
  pointer-events: none;
}

.btn-is-spinning {
  pointer-events: none;
  padding-left: 30px;
}

.btn--small {
  border: 2px solid var(--dark);
  height: 24px !important;
  font-size: 16px;
  border: 1px solid var(--dark);
  box-shadow: 2px 2px 0px var(--dark);
}