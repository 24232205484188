.opponents {

  &__opponents-list {
    margin-bottom: 24px;
    margin-top: 24px;
    position: relative;

    &__opponent {
      text-align: start;
      padding: 4px 0;
      cursor: pointer;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-left: 24px;

      &:hover {
        &::before {
          content: '';
          position: absolute;
          width: 0; 
          height: 0; 
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          
          border-left: 8px solid var(--light);
          left: -8px;
          top: 50%;
          transform: translate(-125%, -50%);
        }
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}