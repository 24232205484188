.day-widget {
  position: relative;
  width: 100%;
  height: 45px;
  width: 45px;
  background-position: center;
  background-size: contain;
  text-align: center;
  color: var(--dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  margin: 0 auto;

  img {
    margin: 0 auto;
  }

  &--pulse {
    animation: pulse 1.5s infinite;
    cursor: pointer;
    z-index: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}