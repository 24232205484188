.navigation-button {
  cursor: pointer;
  font-size: 16px;
  position: relative;
  color: var(--light);
  font-weight: 400;
  text-align: start;
  margin: 6px;
  margin-left: 24px;
  padding: 8px 4px;
  font-family: "Press Start 2P", monospace;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  &:hover,
  &--is-default {
    background: var(--light);
    color: var(--dark);

    &::before {
      content: '';
      position: absolute;
      width: 0; 
      height: 0; 
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      
      border-left: 8px solid var(--light);
      left: -8px;
      top: 50%;
      transform: translate(-125%, -50%);
    }
  }

  &--flicker {
    animation: flash .3s;
  }
  
  @keyframes flash {
    0% {
      background: var(--light);
    }
    50% {
      background: var(--light50);
    }
    100% {
      background: var(--light);
    }
  }

  &--light {
    // background: rgba(255, 255, 255, 0.7);
    // background: url('./stoneTileLarge.png'); 
    // color: var(--dark);
    // background: white;
  }
  
  &--dark {
    // background: white;
    // color: var(--dark);
  }
}