.toast {
  max-width: 600px;
  margin: 0 auto;
  height: fit-content;
  border-radius: 15px;
  background: var(--light);
  color: var(--darkText);
  margin-top: 12px;
  padding: 12px;
  box-shadow: var(--shadow);
  cursor: pointer;
}