.create-battle-card {
  position: relative;
  margin: 24px auto;
  width: 100%;
  max-width: 600px;
  background: var(--light);
  border: 4px solid var(--dark);
  font-weight: 400;
  border-radius: 15px;
  box-shadow: var(--shadow);
  padding: 24px;
  color: var(--dark);

  &__stage {
    position: absolute;
    top: 12px;
    right: 12px;
    color: var(--dark50);
  }
  
  h4 {
    margin-bottom: 24px;
  }

  &__example-text {
    font-size: 12px;
    margin-top: -12px;
  }

  .form-element__label {
    font-weight: bold;
    margin-bottom: 0;
  }

  &__content {
    p,
    ul {
      margin-bottom: 24px;
    }

    li {
      margin-left: 24px;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .form-element {
      margin-top: 24px;
    }
  }

  &__btn-cont {
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;
    flex-wrap: wrap;
    margin-top: 24px;
  }
}