.repeat-battle {

  input {
    margin-bottom: 18px;
  }

  label {
    padding-left: 4px;
  }

  &__battle-list {
    margin-bottom: 24px;
    margin-top: 24px;
  }
}