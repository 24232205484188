.battle-line {
  text-align: start;
  padding: 4px 0;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: var(--light);
    }
  }
}