.welcome-page {
  position: relative;

  &__see-more {
    margin-top: 100px;
    width: 100%;
    position: relative;

    &__block {
      position: relative;
      width: 90%;
      max-width: 600px;
      font-weight: 400;
      border-radius: 15px;
      color: var(--dark);
      margin-bottom: 40px;

      display: grid;
      grid-template-columns: 50% 50%;
      column-gap: 24px;

      h4 {
        grid-column: 1 / 3;
      }

      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}