.battle-complete {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-around;

  > * {
    position: relative;
    text-align: center;
    width: 48%;
  }

  &__username {
    position: relative;
    font-size: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 24px;
  }

  &__stats-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    
    &__stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 12px;
      flex-basis: 100%;
      
      &--first {
        flex-basis: 100%;
      }

      &__name {
        font-size: 14px;
        opacity: .5;
      }
    }
  }

  &__winner-icon {
    position: absolute;
    background: var(--primary);
    border-radius: 5px;
    padding: 2px 12px;
    border: 2px solid var(--light);
    margin-left: 50%;
    transform: translate(-50%, 20px) rotate(-3deg);
  }
}