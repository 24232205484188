.battle-card {
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  width: 200px;
  height: 330px;
  border-radius: 15px;

  &__user {
    border: 5px solid var(--dark);
    box-sizing: content-box;
    padding: 6px;

    &::before {
      content: 'Player 1';
      position: absolute;
      top: -20px;
      left: -4px;
      height: 24px;
      color: var(--dark);
      font-family: "Press Start 2P", monospace;
      font-size: 12px;
    }
  }

  .battle-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &:hover .battle-card-inner {
    transform: rotateY(180deg);
  }

  .contestant-card,
  .contestant-card--back {
    position: absolute;
    width: 200px;
    height: 330px;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  .contestant-card {
    > * {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  
    &__card {
      position: relative;
      width: 200px;
    }
  
    &__username {
      top: 24px;
      font-size: 14px;
    }
  
    &__avatar {
      width: 120px;
      top: 69px;
    }
  
    &__health-bar {
      width: 70%;
      height: 12px;
      border-radius: 5px;
      background-color: var(--red);
      bottom: 44px;
      border: 2px solid var(--dark);
      border-right: 37px solid var(--dark);
    }
  
    &__level {
      bottom: 24px;
    }
  
    &--back {
      transform: rotateY(180deg) scale(1.4);
      
      &__title {
        position: absolute;
        top: 24px;
        font-size: 14px;
        left: 50%;
        transform: translateX(-50%);
      }
    
      &__targets {
        position: absolute;
        top: 60px;
        left: 24px;
        text-align: start;
      }
    }
  }
}